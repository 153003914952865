import React from 'react'
import { Layout, TakweenStroy, Seo } from '../components'

export default function Story() {
  return (
    <Layout>
      <Seo title="Takween Stroy" />
      <TakweenStroy />
    </Layout>
  )
}
